import { Grid, makeStyles } from '@material-ui/core';
import LinkButton from './button/Link';
import GradientDivider from './GradientDivider';

const useStyle = makeStyles(() => ({
  root: {
    color: '#282c34',
    backgroundColor: 'white',
    paddingTop: '20px',
    paddingBottom: '40px',
  },
  join: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  joinDescription: {
    textAlign: 'left',
  },
  button: {
    color: 'white',
    width: '80%',
    maxWidth: 400,
    fontWeight: 'bold',
    margin: '20px',
  },
}));

const JoinArtist = () => {
  const content =
    'CISUMにご興味をお持ちいただいたアーティストの方は、以下の参加方法をご覧ください。';
  const classes = useStyle();
  return (
    <>
      <Grid container justify='center' spacing={1} className={classes.root}>
        <Grid item xs={12}>
          <h3 className={classes.join}>
            参加をご希望の
            <br />
            アーティストの皆さんへ
          </h3>
        </Grid>
        <Grid item xs={10}>
          <GradientDivider />
        </Grid>
        <Grid item xs={10}>
          <p className={classes.joinDescription}>{content}</p>
        </Grid>
        <LinkButton
          to='https://different-clematis-edf.notion.site/33d1e36577c14ac2b9d217b6286248ef'
          title='参加方法を確認する'
          className={classes.button}
          analyticsEventName='click_how_to_join'
        />
      </Grid>
    </>
  );
};

export default JoinArtist;
