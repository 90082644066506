import { Facebook } from '@material-ui/icons';
import { analytics } from '../auth/Firebase';

const FacebookIconLink = ({ style = '', className = '', link = '' }) => {
  const onClick = () => {
    analytics.logEvent(analytics.getAnalytics(), 'click_cisum_facebook');
  };
  return (
    <a
      style={style}
      className={className}
      href={link === '' ? 'https://www.facebook.com/' : link}
      target='_blank'
      rel='noreferrer'
      onClick={onClick}
    >
      <Facebook />
    </a>
  );
};

export default FacebookIconLink;
