import { createStyles, Grid } from '@material-ui/core';
import About from './About';
import FacebookIconLink from './footer/facebook';
import InstagramIconLink from './footer/instagram';
import MailIconLink from './footer/mail';
import TwitterIconLink from './footer/twitter';
import JoinArtist from './JoinArtist';
import NewInfo from './NewInfo';

const useStyle = createStyles({
  footer: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  icon: {
    color: 'silver',
    fontSize: '22px',
    marginLeft: '10px',
    marginRight: '10px',
    padding: '5px',
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: '50%',
    display: 'flex',
  },
});

const Footer = () => {
  const classes = useStyle;
  return (
    <>
      <About />
      <NewInfo />
      <JoinArtist />
      <Grid container justify='center' spacing={1} style={classes.footer}>
        <Grid item xs={1} />
        <Grid item container xs={10} direction='row' justify='center'>
          <TwitterIconLink
            style={classes.icon}
            link='https://twitter.com/cisum_jp'
          />
          <FacebookIconLink
            style={classes.icon}
            link='https://www.facebook.com/cisum.official/'
          />
          <InstagramIconLink
            style={classes.icon}
            link='https://www.instagram.com/cisum.official/?hl=ja'
          />
          <MailIconLink style={classes.icon} link='info@cisum-music.com' />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

export default Footer;
