import { Button, DialogContent, DialogTitle, TextField, Typography, DialogActions, Dialog } from "@material-ui/core";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles"
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    buttonStyle: {
      backgroundColor: '#4DA0AF',
      width: '311px',
      height: '48px',
    },
    registerButtonStyle: {
      color: '#4DA0AF',
      width: '311px',
      height: '48px',
    },
    dialogButtonAlign: {
      justifyContent: 'center',
    },
  }));

export default function RegisterCampaignDialog(props) {
    const { openPopup, setOpenPopup } = props 
    const [registerable, setRegisterable] = useState(false)
    const classes = useStyles()

    const [code, setCode] = useState("")

    const handleCodeRegister = () => {
        let email = localStorage.getItem("user_email")
        const req = {
            email: email,
            campaign_code: code,
        }

        axios.post(`${process.env.REACT_APP_HTTP_HOST}/cisum/listener/check_teaser_auth`, { email: req.email, campaign_code: req.campaign_code })
            .then((res) => {
                localStorage.setItem("hasMembership", true)
                setOpenPopup(false)
            })
            .catch((error) => {
                alert(error)
            })
    }

    const checkRegisterable = () => {
        if (code != "") {
            setRegisterable(true)
        } else {
            setRegisterable(false)
        }
    }

    return (
        <>
        <Dialog open={openPopup} onClose={() => {
            setOpenPopup(false)
        }}>
            <DialogTitle disableTypography>
                <Typography variant="h5" align="center">キャンペーンコード登録</Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin='dense'
                    id='code-field'
                    label='キャンペーンコード'
                    type='code'
                    variant='outlined'
                    fullWidth
                    onChange={(event) => {
                        setCode(event.target.value)
                        checkRegisterable()
                    }}
                />
            </DialogContent>
            <DialogActions className={classes.dialogButtonAlign}>
                <Button
                    className={classes.buttonStyle}
                    variant="contained"
                    onClick={handleCodeRegister}
                    color="primary"
                    disabled={!registerable}
                >
                  キャンペーンコードを登録
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}
