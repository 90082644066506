import React, { useState } from 'react';
import RegisterDialog from './RegisterDialog';
import LoginDialog from './LoginDialog';
import RegisterCampaignDialog from './RegisterCampaignDialog';

export default function Popup(props) {
  const { openPopup, setOpenPopup } = props;
  const [openInputCode, setOpenInputCode] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  return (
    <>
      <RegisterDialog openPopup={openRegister} setOpenPopup={setOpenRegister} />
      <LoginDialog
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setOpenRegister={setOpenRegister}
        setOpenInputCode={setOpenInputCode}
      />
      <RegisterCampaignDialog
        openPopup={openInputCode}
        setOpenPopup={setOpenInputCode}
      />
    </>
  );
}
