import { createStyles } from '@material-ui/core';

const useStyles = createStyles({
  root: {
    height: '2px',
    width: '30px',
    background:
      'linear-gradient(to right, rgba(255, 51, 51, 0.6) 20%, rgba(0, 177, 187, 0.6) 80.71%)',
    textAlign: 'center',
    margin: '0 auto',
  },
});

const GradientDivider = () => {
  const classes = useStyles;
  return (
    <>
      <div style={classes.root}></div>
    </>
  );
};

export default GradientDivider;
