import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './components/Home';
// import Artist from './components/Artist';
import ScrollToTop from './components/ScrollTop';
// import Page404 from './components/Page404';

function App() {
  return (
    <div id='App'>
      <Router>
        <ScrollToTop />
        <Route exact path='/' component={Home} />
        {
          // TODO: サイトを公開するときにコメントアウトを外す
          // TODO: 外すときは dev で動作確認してから master へと公開すること
        }
        {/* <Route exact path='/home' component={Home} />
        <Route exact path='/artists/:id' component={Artist} />
        <Route exact path='/notFound' component={Page404} /> */}
      </Router>
    </div>
  );
}

export default App;
