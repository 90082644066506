import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  createStyles,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@material-ui/core';
import Popup from './auth/login';
import { Inbox, Menu as MenuIcon } from '@material-ui/icons';
import logo from '../assets/images/header-logo.svg';
import TwitterIconLink from './footer/twitter';
import FacebookIconLink from './footer/facebook';
import YouTubeIconLink from './footer/youtube';
import MailIconLink from './footer/mail';
import InstagramIconLink from './footer/instagram';

const useStyles = createStyles({
  root: {
    backgroundColor: '#f5f5f5',
    height: 60,
  },
  signButton: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  itemText: {
    color: '#11162b',
    width: 35,
    height: 35,
  },
  accountIcon: {
    color: '#11162b',
  },
  listStyle: {
    minWidth: '250px',
    height: '200px',
    background:
      'linear-gradient(127deg, rgba(254, 225, 64, 0.8) 20%, rgba(245, 87, 108, 0.7) 80.71%)',
    padding: '20px',
  },
  linkIcon: {
    color: '#11162b',
    marginLeft: '5px',
    marginRight: '5px',
    padding: '5px',
  },
});

const Header = () => {
  const classes = useStyles;
  const [state, setState] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [headerButton, setHeaderButton] = useState(
    <Button
      style={classes.signButton}
      variant='outlined'
      onClick={() => setOpenPopup(true)}
    >
      新規登録
      <br />
      ログイン
    </Button>
  );
  const [buttonTitle, setButtonTitle] = useState('新規登録\nログイン');
  const [isRegistered, setIsRegistered] = useState(
    localStorage.getItem('isRegistered')
  );
  const [hasMembership, setHasMembership] = useState(
    localStorage.getItem('hasMembership')
  );

  useEffect(() => {
    var registered = localStorage.getItem('isRegistered');
    setIsRegistered(registered);
    var membership = localStorage.getItem('hasMembership');
    setHasMembership(membership);
    if (isRegistered === 'true' && hasMembership === 'true') {
      setHeaderButton(<></>);
      setButtonTitle('');
      return;
    }

    if (isRegistered === 'true' && hasMembership !== 'true') {
      setHeaderButton(
        <Button
          style={classes.signButton}
          variant='outlined'
          onClick={() => setOpenPopup(true)}
        >
          コード登録
        </Button>
      );
      setButtonTitle('キャンペーンコード');
      return;
    }

    setHeaderButton(
      <Button
        style={classes.signButton}
        variant='outlined'
        onClick={() => setOpenPopup(true)}
      >
        新規登録
        <br />
        ログイン
      </Button>
    );
    setButtonTitle('新規登録\nログイン');
  }, [openPopup]);

  return (
    <>
      <AppBar style={classes.root} position='static'>
        <Toolbar>
          <Drawer anchor='left' open={state} onClose={() => setState(false)}>
            <div
              onClick={() => setState(false)}
              onKeyDown={() => setState(false)}
            >
              <div style={classes.listStyle}>
                <img src={logo} alt='logo' />
              </div>
              {
                // TODO: サイトを公開するときにコメントアウトを外す
                // TODO: 外すときは dev で動作確認してから master へと公開すること
              }
              {/* <List>
                {hasMembership === 'true' ? (
                  <></>
                ) : (
                  <ListItem
                    button
                    onClick={() => {
                      setOpenPopup(true);
                    }}
                  >
                    <ListItemIcon>
                      <Inbox />
                    </ListItemIcon>
                    <ListItemText
                      style={classes.itemText}
                      primary={buttonTitle}
                    />
                  </ListItem>
                )}
              </List> */}
              <div>
                <TwitterIconLink
                  style={classes.linkIcon}
                  link='https://twitter.com/cisum_jp'
                />
                <FacebookIconLink
                  style={classes.linkIcon}
                  link='https://www.facebook.com/cisum.official/'
                />
                <YouTubeIconLink
                  style={classes.linkIcon}
                  link='https://www.youtube.com/channel/UCU3ZVcs4DOkV3W9U1nP95rA'
                />
                <MailIconLink
                  style={classes.linkIcon}
                  link='info@cisum-music.com'
                />
                <InstagramIconLink
                  style={classes.linkIcon}
                  link='https://www.instagram.com/cisum.official/?hl=ja'
                />
              </div>
            </div>
          </Drawer>

          <Grid container alignItems='center' justify='flex-start' spacing={1}>
            <Grid item xs={2}>
              <IconButton aria-label='menu' onClick={() => setState(true)}>
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={6}>
              {/*
                // TODO: サイトを公開するときにコメントアウトを外す
                // TODO: 外すときは dev で動作確認してから master へと公開すること
                <a href='/home'>
              */}
              <a href='/'>
                <img src={logo} alt='logo' />
              </a>
            </Grid>
            {
              // TODO: サイトを公開するときにコメントアウトを外す
              // TODO: 外すときは dev で動作確認してから master へと公開すること
            }
            {/* <Grid item xs={3}>
              {headerButton}
            </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}></Popup>
    </>
  );
};

export default Header;
