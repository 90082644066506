import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '2px',
    width: '30px',
    background:
      'linear-gradient(to right, rgba(255, 51, 51, 0.6) 20%, rgba(0, 177, 187, 0.6) 80.71%)',
    textAlign: 'center',
    margin: '0 auto',
  },
}));

const CustomGradientDivider = (props) => {
  const { background } = props;
  const classes = useStyles();
  return (
    <>
      <div className={classes.root} style={{ background: background }} />
    </>
  );
};

export default CustomGradientDivider;
