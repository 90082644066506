import {
  Button,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  DialogActions,
  Dialog,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import firebase from './Firebase';
import { makeStyles } from '@material-ui/core/styles';
import CustomGradientDivider from '../CustomGradientDivider';

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 350,
    margin: '0 auto',
  },
  buttonStyle: {
    backgroundColor: '#4DA0AF',
    width: 250,
    height: 48,
    fontWeight: 'bold',
    borderRadius: 25,
    '&:hover': {
      background: '#4DA0AF',
    },
  },
  headerFont: {
    fontWeight: 'bold',
  },
  registerButtonStyle: {
    color: '#4DA0AF',
    width: '311px',
    height: '48px',
  },
  dialogButtonAlign: {
    justifyContent: 'center',
  },
}));

export default function RegisterDialog(props) {
  const { openPopup, setOpenPopup } = props;
  const [state, setState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [registerable, setRegisterable] = useState(false);
  const [pswdValid, setPswdValid] = useState(true);

  const classes = useStyles();

  const handleRegister = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(state.email, state.password)
      .then((res) => {
        setOpenPopup(false);
        localStorage.setItem('user_email', state.email);
        localStorage.setItem('isRegistered', true);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const checkPasswordValid = (pswd, cpswd) => {
    if (pswd != '' && pswd != cpswd) {
      setPswdValid(false);
    }
  };

  const checkRegisterable = () => {
    if (state.email != '' && state.password != '' && pswdValid) {
      setRegisterable(true);
    } else {
      setRegisterable(false);
    }
  };

  useEffect(() => {
    checkRegisterable();
  }, [state]);

  return (
    <>
      <Dialog
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
        }}
        className={classes.dialog}
      >
        <DialogTitle>
          <Typography
            className={classes.headerFont}
            variant='h6'
            align='center'
          >
            新規会員登録
          </Typography>
          <CustomGradientDivider background='#5AA0AE' />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            id='mail-field'
            label='メールアドレス'
            type='email'
            variant='outlined'
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              const s = { ...state, email: event.target.value };
              setState(s);
            }}
          />
          <TextField
            error={!pswdValid}
            autoFocus
            margin='dense'
            id='name'
            label='パスワード'
            type='password'
            variant='outlined'
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              const s = { ...state, password: event.target.value };
              setState(s);
            }}
          />
          <TextField
            error={!pswdValid}
            autoFocus
            margin='dense'
            id='name'
            label='パスワードの再入力（確認用）'
            type='password'
            variant='outlined'
            fullWidth
            InputLabelProps={{ shrink: true }}
            helperText={pswdValid ? '' : 'パスワードが一致しません'}
            onChange={(event) => {
              const s = { ...state, confirmPassword: event.target.value };
              setState(s);
              checkPasswordValid(s.password, event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions className={classes.dialogButtonAlign}>
          <Button
            className={classes.buttonStyle}
            variant='contained'
            onClick={handleRegister}
            color='primary'
            disabled={!registerable}
          >
            新規会員登録
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
