import { Grid, makeStyles } from '@material-ui/core';
import GradientDivider from './GradientDivider';
import LinkButton from './button/Link';

const useStyle = makeStyles(() => ({
  root: {
    color: '#282c34',
    backgroundColor: '#D5D5D5',
    paddingTop: '20px',
    paddingBottom: '40px',
  },
  info: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  infoDescription: {
    textAlign: 'left',
  },
  button: {
    color: 'white',
    width: '80%',
    fontWeight: 'bold',
    margin: '20px',
  },
}));

const NewInfo = () => {
  const content =
    'メールマガジンでは新しい参加アーティストや機能のことなど最新のNews / Topicsを配信します。';
  const classes = useStyle();
  return (
    <>
      <Grid container justify='center' spacing={1} className={classes.root}>
        <Grid item xs={12}>
          <h3 className={classes.info}>新着情報を受け取る</h3>
        </Grid>
        <Grid item xs={10}>
          <GradientDivider />
        </Grid>
        <Grid item xs={10}>
          <p className={classes.infoDescription}>{content}</p>
        </Grid>
        <LinkButton
          to='https://forms.gle/VNHc28RazLKjPEnB8'
          title='簡単なアンケートに答えて<br />新着情報を受け取る'
          className={classes.button}
          analyticsEventName='click_mail_magagine'
        />
      </Grid>
    </>
  );
};

export default NewInfo;
