import React from 'react';
// import HomeArtistCard from './HomeArtistCard';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  //   artistStyle: {
  //     marginBottom: 70,
  //     height: '85vh',
  //     width: '100%',
  //   },
  //   eyeCatchImageSp: {
  //     position: 'relative',
  //     objectFit: 'cover',
  //     width: '100%',
  //     maxHeight: '60vh',
  //   },
  //   eyeCatchImagePc: {
  //     position: 'relative',
  //     objectFit: 'contain',
  //     width: '100%',
  //     maxHeight: '60vh',
  //   },
  //   aritistLink: {
  //     width: 300,
  //     margin: '0 auto',
  //     marginTop: 30,
  //   },
  //   touchSensitivity: {
  //     color: 'gray',
  //     textAlign: 'left',
  //   },
  //   artistCard: {
  //     margin: 20,
  //   },
  topImage: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
}));

const Home = () => {
  // const theme = useTheme();
  const classes = useStyles();
  // const xsMatches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Header />
      <img
        src='https://storage.googleapis.com/cisum-teaser/top/cf-kv-v3-06.png'
        alt='アーティストや曲の知られざるルーツや歴史を知る'
        className={classes.topImage}
      />
      {
        // TODO: サイトを公開するときにコメントアウトを外す
        // TODO: 外すときは dev で動作確認してから master へと公開すること
      }
      {/* <div className={classes.artistStyle}>
        <img
          className={
            xsMatches ? classes.eyeCatchImageSp : classes.eyeCatchImagePc
          }
          src={
            xsMatches
              ? 'https://storage.googleapis.com/cisum-teaser/top/top-first-sp.png'
              : 'https://storage.googleapis.com/cisum-teaser/top/top-first-sp.png'
          }
          alt='サンプル画像'
        />
        <div className={classes.aritistLink}>
          <p className={classes.touchSensitivity}>Touch Sensitivity</p>
          <HomeArtistCard
            image='https://storage.googleapis.com/cisum-teaser/seiko-ito-io-artist/1961-%E3%81%84%E3%81%A8%E3%81%86-%E3%81%9B%E3%81%84%E3%81%93%E3%81%86.png'
            to='/artists/15'
            title='いとうせいこう'
            className={classes.artistCard}
          />
          <HomeArtistCard
            image='https://storage.googleapis.com/cisum-teaser/gota-yashiki-io-artist/1962-%E5%B1%8B%E6%95%B7%E8%B1%AA%E5%A4%AA.png'
            to='/artists/1'
            title='屋敷豪太'
            className={classes.artistCard}
          />
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default Home;
