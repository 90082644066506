import { Mail } from '@material-ui/icons';
import { analytics } from '../auth/Firebase';

const MailIconLink = ({ style = '', className = '', link = '' }) => {
  const onClick = () => {
    analytics.logEvent(analytics.getAnalytics(), 'click_cisum_mail');
  };
  return (
    <a
      style={style}
      className={className}
      href={link === '' ? 'mailto:info@cisum-music.com' : `mailto:${link}`}
      target='_blank'
      rel='noreferrer'
      onClick={onClick}
    >
      <Mail />
    </a>
  );
};

export default MailIconLink;
