import {
  Button,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  DialogActions,
  Dialog,
} from '@material-ui/core';
import { useState } from 'react';
import firebase from './Firebase';
import { makeStyles } from '@material-ui/core/styles';
import CustomGradientDivider from '../CustomGradientDivider';

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 350,
    margin: '0 auto',
  },
  buttonStyle: {
    backgroundColor: '#4DA0AF',
    width: 250,
    height: 48,
    fontWeight: 'bold',
    borderRadius: 25,
    '&:hover': {
      background: '#4DA0AF',
    },
  },
  registerButtonStyle: {
    color: '#4DA0AF',
    width: 250,
    height: 48,
  },
  headerFont: {
    fontWeight: 'bold',
  },
  dialogButtonAlign: {
    justifyContent: 'center',
  },
}));

export default function LoginDialog(props) {
  const { openPopup, setOpenPopup, setOpenRegister, setOpenInputCode } = props;
  const [registerable, setRegisterable] = useState(false);
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        localStorage.setItem('user_email', email);
        localStorage.setItem('isRegistered', true);
        setOpenPopup(false);
        const isMember = localStorage.getItem('hasMembership');
        if (isMember != true) {
          setOpenInputCode(true);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleOpenRegister = () => {
    setOpenPopup(false);
    setOpenRegister(true);
  };

  const checkRegisterable = () => {
    if (email != '' && password != '') {
      setRegisterable(true);
    } else {
      setRegisterable(false);
    }
  };

  return (
    <>
      <Dialog
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
        }}
        className={classes.dialog}
      >
        <DialogTitle disableTypography>
          <Typography
            className={classes.headerFont}
            variant='h6'
            align='center'
          >
            ログイン
          </Typography>
          <CustomGradientDivider background='#5AA0AE' />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            id='email-field'
            label='メールアドレス'
            type='email'
            variant='outlined'
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              setEmail(event.target.value);
              checkRegisterable();
            }}
          />
          <TextField
            autoFocus
            margin='dense'
            id='pass-field'
            label='パスワード'
            type='password'
            variant='outlined'
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              setPassword(event.target.value);
              checkRegisterable();
            }}
          />
        </DialogContent>
        <DialogActions className={classes.dialogButtonAlign}>
          <Button
            className={classes.buttonStyle}
            variant='contained'
            onClick={handleLogin}
            color='primary'
            disabled={!registerable}
          >
            ログイン
          </Button>
        </DialogActions>
        <DialogActions className={classes.dialogButtonAlign}>
          <Button
            className={classes.registerButtonStyle}
            onClick={handleOpenRegister}
            color='primary'
          >
            新規登録
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
