import { Button, Grid, makeStyles } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { analytics } from '../auth/Firebase';

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
    backgroundColor: '#24293E',
    width: '100%',
    maxWidth: 370,
    minHeight: 50,
    fontWeight: 'bold',
    '&:hover': {
      background: '#24293E',
    },
  },
  link: {
    color: '#282c34',
    textDecoration: 'none',
  },
  linkTitle: {
    width: '100vw',
  },
  arrow: {
    fontSize: '16px',
    display: 'flex',
    margin: '0 0 0 auto',
  },
}));

const LinkButton = ({
  to = '',
  title = '',
  className = {},
  analyticsEventName = '',
}) => {
  const classes = useStyles();
  const HTMLComponent = (title) => {
    return <span dangerouslySetInnerHTML={{ __html: title }}></span>;
  };
  const onClick = () => {
    if (analyticsEventName === '') {
      return;
    }
    analytics.logEvent(analytics.getAnalytics(), analyticsEventName);
  };
  return (
    <div className={className}>
      <a
        href={to}
        className={classes.link}
        target='_blank'
        rel='noreferrer'
        onClick={onClick}
      >
        <Button variant='contained' className={classes.button}>
          <div className={classes.linkTitle}>{HTMLComponent(title)}</div>
          <ArrowForwardIos className={classes.arrow} />
        </Button>
      </a>
    </div>
  );
};

export default LinkButton;
