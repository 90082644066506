import { Grid, makeStyles } from '@material-ui/core';
import LinkButton from './button/Link';
import GradientDivider from './GradientDivider';

const useStyle = makeStyles(() => ({
  root: {
    color: '#282c34',
    backgroundColor: 'white',
    paddingTop: '20px',
    paddingBottom: '40px',
  },
  about: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  aboutDescriptoin: {
    textAlign: 'left',
  },
  button: {
    color: 'white',
    width: '80%',
    fontWeight: 'bold',
    margin: 20,
  },
}));

const About = () => {
  const title = 'すべての音にはストーリーがある。音楽は、もっと面白くなる。';
  const content =
    'CISUMは、様々なアーティストのルーツ音楽を深堀りしたり、あなたが好きなアーティストが今注目している音楽をリアルタイムに知ることができる、リスナーの「好き」を広げるアーティスト深堀りアプリです。"アーティストの感性に触れる” ”インスピレーションの原泉にあるルーツをたどる” そんな体験をより身近で楽しいものにします。';
  const classes = useStyle();
  return (
    <>
      <Grid container justify='center' spacing={1} className={classes.root}>
        <Grid item xs={12}>
          <h2 className={classes.about}>About</h2>
        </Grid>
        <Grid item xs={10}>
          <GradientDivider />
        </Grid>
        <Grid item xs={10}>
          <h3>{title}</h3>
        </Grid>
        <Grid item xs={10}>
          <p className={classes.aboutDescriptoin}>{content}</p>
        </Grid>
        <LinkButton
          to='https://camp-fire.jp/projects/view/346483'
          title='クラウドファウンディング実施中'
          className={classes.button}
          analyticsEventName='click_crowdfunding'
        />
      </Grid>
    </>
  );
};

export default About;
